<template>
  <div class="notification-box">
    <div v-show="notifications.length > 0" v-for="notification, index in notifications" :key="index">
      <div :class="{success: notification.status, failed: !notification.status}" @click="hideNotification(notification)">
        {{notification.message}} <span @click="hideNotification(notification)" class="close"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      notifications: 'notifications',
    })
  },
  methods: {
    ...mapActions({
      hideNotification: 'hideNotification'
    })
  }
};
</script>

<style>

.notification-box {
  position:fixed;
  bottom:0;
  cursor:pointer;
  width:99%;
}

.notification-box:hover {
  opacity: 0.5;
}

.notification-box:active {
  opacity: 0.3;
}

.success {
  border: 2px solid green;
  font-weight: bold;
  padding: 0.5rem;
  background: rgb(11, 139, 54);
  color: rgb(191, 255, 212);
}

.failed {
  border: 2px solid red;
  font-weight: bold;
  padding: 0.5rem;
  background: rgb(240, 39, 39);
  color:rgb(248, 228, 228);
}
.close {
  position: absolute;
  right:1rem;
  width: 2rem;
  height: 2rem;
  border: 0.15rem solid rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
  margin-top:-0.5rem;
}
.close:hover {
  opacity:0.8;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}


</style>
